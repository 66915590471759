import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User, UserResponse } from "../models/user.model";

export const userApi = createApi({
  reducerPath: "userApi",
    baseQuery: fetchBaseQuery({ baseUrl: "https://partnerportalbe.azurewebsites.net/api/v1/" }),
  endpoints: (builder) => ({
    users: builder.query<User[], void>({
      query: () => "/user",
    }),
    user: builder.query<UserResponse, string>({
      query: (userId) => `/user/${userId}`,
    }),
    addUser: builder.mutation<UserResponse, User>({
      query: (user) => ({
        url: "/user",
        method: "POST",
        body: user,
      }),
    }),
  }),
});

export const { useUsersQuery, useAddUserMutation,useUserQuery } = userApi;
