import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { TStore } from "./store/appStore";
import { useCookies } from 'react-cookie'
import React from "react";
import User from "./components/user";

export type ProtectedLoginProps = {
    authenticationPath: string;
    outlet: JSX.Element;
  };
  
  export default function ProtectedLogin({authenticationPath, outlet}: ProtectedLoginProps) {
    const [cookies, setCookie] = useCookies();
    //const data = useSelector((state: TStore) => state.User);
    const xx=cookies["authsession"]
    console.log(xx)
    if(cookies["authsession"]!=null) {
      return <Navigate to={{ pathname: authenticationPath }} replace/>;
    } else {
      return outlet;
    }
  };
