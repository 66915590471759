import React from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report as PbReport, Embed, service, Page } from "powerbi-client";
import "../App.css";
import { Link, useSearchParams } from "react-router-dom";
import { useReportQuery, useReportTokenQuery } from "../services/report.api";
import { Backdrop, Box, CircularProgress, colors } from "@mui/material";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

async function getActivePage(powerbiReport: PbReport): Promise<Page | undefined> {
  const pages = await powerbiReport.getPages();

  // Get the active page
  const activePage = pages.filter(function (page) {
    return page.isActive
  })[0];

  return activePage;
}
function SummaryReport() {
  const [searchParams] = useSearchParams();
  //const Id = searchParams.get("reportId");
  const Id = "75cd9a41-d265-4baa-b065-98d8113a9c6b";

  const url = "https://app.powerbi.com/reportEmbed?reportId=7a4ac021-b315-4cc5-8b08-86f934d5958a&groupId=a78b78cc-acd3-48be-81e9-5141e49b2b2f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtQy1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiYW5ndWxhck9ubHlSZXBvcnRFbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwWm9uZVBhdGNoIjp0cnVlfX0%3d"
  const { data, error, isLoading, isFetching, isSuccess } = useReportTokenQuery(
    Id!
  );

  const { data: report } = useReportQuery(Id!);
  console.log(report);
  return (
    <div style={{flexDirection: "column", alignItems: "center",alignContent: 'stretch',
    justifyContent: "center"}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "#FFFFFF",
          padding:2
        }}
      >
        {isSuccess && <Link style={{fontSize:'1.2rem'}}
        to={{
          pathname: "/user"
        }}
      >
        Click here for OPEI Exclusive Content
      </Link>}
      </Box>
      <div style={{ display: 'flex' }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        invisible={isSuccess}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && <h2>Something went wrong</h2>}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
         {isSuccess && isBrowser && (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: `${Id}`,
            embedUrl: `${url}`,
            accessToken: `${data?.token}`,
            tokenType: models.TokenType.Embed,
            pageName:'ReportSection9ec4c126bcf0100bfa39',
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function (report) {
                  
                  console.log(report);
                },
              ],
              [
                "rendered",
                function (report) {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  //console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={"Embed-container"}
          getEmbeddedComponent={async (embeddedReport) => {
            (window as any).report=embeddedReport as PbReport
            // const pages = await (embeddedReport as PbReport).getPages();
            // // const xx=embeddedReport as PbReport
            // // var xy=await xx.getPages();
            // console.log(pages + 'humza')
          }}
        />
      )}
      </Box>

      {isSuccess && isMobile && (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: `${Id}`,
            embedUrl: `${url}`,
            accessToken: `${data?.token}`,
            tokenType: models.TokenType.Embed,
            pageName:'ReportSection9ec4c126bcf0100bfa39',
            settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
              layoutType: models.LayoutType.MobilePortrait
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  //console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={"Embed-container"}
          getEmbeddedComponent={(embeddedReport) => {
            const xx=embeddedReport as PbReport
            console.log(xx.getPageByName("Comparison") + 'Humza')
          }}
        />
      )}
    </div>
    </div>
    
  );
}

export default SummaryReport;
