import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Report } from "../models/report.model";
import { ReportToken } from "../models/reportToken.model";

export const reportApi = createApi({
  reducerPath: "reportApi",
    baseQuery: fetchBaseQuery({ baseUrl: "https://partnerportalbe.azurewebsites.net/api/v1/" }),
  endpoints: (builder) => ({
    reports: builder.query<Report[], void>({
      query: () => "/report",
    }),
    reportToken: builder.query<ReportToken, string>({
      query: (reportId) => `/report/embeddedToken/${reportId}`,
    }),
    report: builder.query<Report, string>({
      query: (reportId) => `/report/${reportId}`,
    }),
  }),
});

export const { useReportsQuery, useReportQuery, useReportTokenQuery } =
  reportApi;
