import { Routes, Route, Link } from "react-router-dom";
import Report from "./components/SummaryReport";
import Reports from "./components/reports";
import ProtectedRoute from "./protectedRoute";
import User from "./components/user";
import Landing from "./components/landing";
import Testimonials from "./components/testimonials";
import ProtectedLogin from "./ProtectedLogin";
import ContactUs from "./components/contactus";
import Footer from "./components/footer";
import SummaryReport from "./components/SummaryReport";
import ComparisonReport from "./components/ComparisonReport";
import CRM from "./components/targetcrm";

function App() {
 

  return (
    <div>
    <Routes>
    <Route path="/" element={<SummaryReport></SummaryReport>} />
    <Route path="/comparison" element={<ComparisonReport></ComparisonReport>} />
    <Route path="/testimonials" element={<Testimonials></Testimonials>} />
    <Route path="/target" element={<CRM></CRM>} />
    <Route path="/user" element={<ProtectedLogin outlet={<User></User>} authenticationPath={"/comparison"}></ProtectedLogin>}/>
    {/* <Route path="/contactus" element={<ContactUs></ContactUs>} /> */}
      {/* <Route path="/" element={<ProtectedLogin outlet={<User></User>} authenticationPath={"/"}></ProtectedLogin>} /> */}
      {/* <Route path="/landing" element={<Landing></Landing>} /> */}
      {/* <Route path="reports" element={<ProtectedRoute outlet={<Reports></Reports>} authenticationPath={"/"}></ProtectedRoute>} /> */}
    </Routes>

    <Footer></Footer>
  </div>
  );
}
export default App;
