import {
    MiddlewareAPI,
    isRejectedWithValue,
    Middleware,
  } from '@reduxjs/toolkit'

  export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
      if (isRejectedWithValue(action)) {
          if(action.payload && action.payload.status==400 && action.payload.data.Errors){
            var detial=`${Object.entries(action.payload.data.Errors).map(([k,v]) => `${v}`)}`
            console.warn(detial)
            action.customError=detial;
          }
      }
  
      return next(action)
    }