import { configureStore } from "@reduxjs/toolkit";
import { reportApi } from "../services/report.api";
import { userApi } from "../services/user.api";
import rootReducer from "./appReducer";
import {rtkQueryErrorLogger} from '../middleware/errorHandling'

export const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(reportApi.middleware)
      .concat(userApi.middleware),
});
export type TStore = ReturnType<typeof appStore.getState>;
