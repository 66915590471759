import { combineReducers } from "redux";
import { reportApi } from "../services/report.api";
import { userApi } from "../services/user.api";
import UserReducer from "./user/userSlice";

export default combineReducers({
  [reportApi.reducerPath]: reportApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  User: UserReducer,
});
