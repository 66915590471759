import React, { FC, useEffect } from "react";
import {
  Container,
  CssBaseline,
  TextField,
  Button,
  Typography,
  ThemeProvider,
  Box,
  createTheme,
  Snackbar,
  IconButton
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from "react-redux";
import { TStore } from "../store/appStore";
import { setemail, setError, setname, setRegisterEnable, setRegisterfailed } from "../store/user/userSlice";
import { useAddUserMutation } from "../services/user.api";
import CloseIcon from "@mui/icons-material/Close";
import { Navigate, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie'


const theme = createTheme();


const User: FC = () => {
  const [cookies, setCookie] = useCookies()
  const dispatch = useDispatch();
  const userData = useSelector((state: TStore) => state.User);
  const [addUser, { data, isSuccess }] = useAddUserMutation();
  let navigate = useNavigate();
  useEffect(() => {

    if (userData.name !== '' && userData.email !== '') {
      dispatch(setRegisterEnable({ isButtonDisabled: false }));
    } else {
      dispatch(setRegisterEnable({ isButtonDisabled: true }));
    }
  }, [userData.name, userData.email, dispatch]);



  async function handleSubmit(e: any) {
      let d = new Date();
        d.setTime(d.getTime() + (1000*60*60));
    e.preventDefault();
    var user = {
      name: userData.name,
      email: userData.email
    };

    await addUser(user)
      .unwrap()
      .then((payload) => {
       setCookie('authsession', payload.id, { path: "/", expires:d })
        navigate("/comparison", { replace: true });
      })
      .catch((error) => {
        if (error && error.status === 400) {
          console.log(error)
          var detial = `${Object.entries(error.userData.Errors).map(([k, v]) => `${v}`)}`
          dispatch(setRegisterfailed({ helperText: detial }))
        }
      });


  }

  const HandleNameChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: input,
  }) => {
    dispatch(setname({ name: input.value }));
  };

  const HandleEmailChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: input,
  }) => {
    dispatch(setemail({ email: input.value }));
  };

  const handleSnackClose: any = () => {
    dispatch(setError({ isError: false }));
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Snackbar
          key="error"
          open={userData.isError}
          autoHideDuration={6000}
          message={userData.helperText}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="error"
                sx={{ p: 0.5 }}
                onClick={handleSnackClose}
              >
                <CloseIcon></CloseIcon>
              </IconButton>
            </React.Fragment>
          }
        />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#44444",
          }}
        >
          <Typography component="h1" variant="h5">
            Please provide below information for OPEI exclusive content
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              onChange={HandleNameChange}
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
            />
            <TextField
              margin="normal"
              onChange={HandleEmailChange}
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={userData.isButtonDisabled}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default User;

