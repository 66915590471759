import { createSlice } from "@reduxjs/toolkit";

export interface UserModel {
  name: string;
  email: string;
  Company: string
  Industry: string
  Comments: string
  Phone: string,
  isShowContactUsDiv:boolean,
  isShowAckDiv:boolean,
  isButtonDisabled: boolean;
  helperText: string;
  isError: boolean;
}

export const LogininitialState = {
  name: "",
  email: "",
  Comments:"",
  Company: "",
  Industry: "",
  Phone:"",
  isShowContactUsDiv:true,
  isShowAckDiv:false,
  isButtonDisabled: true,
  helperText: "",
  isError: false,
} as UserModel;

const userSliece = createSlice({
  name: "user",
  initialState: LogininitialState,
  reducers: {
    setname: (state, action) => {
      state.name = action.payload.name;
    },
    setemail: (state, action) => {
      state.email = action.payload.email;
    },
    setCompany: (state, action) => {
      state.Company = action.payload.Company;
    },
    setComments: (state, action) => {
      state.Comments = action.payload.Comments;
    },
    setIndustry: (state, action) => {
      state.Industry = action.payload.Industry;
    },
    setPhone: (state, action) => {
      state.Phone = action.payload.Phone;
    },
    setRegisterEnable: (state, action) => {
      state.isButtonDisabled = action.payload.isButtonDisabled;
    },
    setShowContactUsDiv: (state, action) => {
      state.isShowContactUsDiv = action.payload.isShowContactUsDiv;
    },
    setShowAckDiv: (state, action) => {
      state.isShowAckDiv = action.payload.isShowAckDiv;
    },
    setRegisterfailed: (state, action) => {
      state.helperText = action.payload.helperText;
      state.isError = true;
    },
    setError: (state, action) => {
      state.isError = action.payload.isError;
    },
  },
});

export const {
  setname,
  setemail,
  setCompany,
  setComments,
  setIndustry,
  setPhone,
  setRegisterEnable,
  setRegisterfailed,
  setError,
  setShowContactUsDiv,
  setShowAckDiv
} = userSliece.actions;
export default userSliece.reducer;
