import { Link } from "react-router-dom";
//import { useOidcAccessToken } from "@axa-fr/react-oidc-context";
//import Report from "./Report";
import { useReportsQuery } from "../services/report.api";
import User from "./user";

function Footer() {
  return (
<div>
<footer className="c-footer">
		<div className="container">
			<div className="logo-footer"><a href="https://opedata.com/"><img
						src="./webassets/ConstellationDataSolutionsLogo.png"
						alt="Constellation Dealership Software" title="Constellation Dealership Software" /></a></div>
		</div>
		<div className="container_wrap" id="socket" role="contentinfo" >
			<div className="container">
				<div className="copyright">© 2022 Copyright - <a href="https://constellationdealer.com/">Constellation
						Dealership Software.</a> All rights reserved.</div>
				<div className="menu-footer">
					<ul>
						<li><a href="https://constellationdealer.com/privacy-policy/">Privacy Policy</a></li>
						<li><a href="https://constellationdealer.com/terms/">Terms</a></li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
	<a href="https://constellationdealer.com/market-data/#top" title="Scroll to top" id="scroll-top-link"
		aria-hidden="true" data-av_icon="" data-av_iconfont="entypo-fontello"><span
			className="avia_hidden_link_text">Scroll to top</span></a>
</div>
  );
}

export default Footer;
