import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './testimonials.css'

function Testimonials() {
  return (
    <div className="App">
    <header className="App-header">
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <div className="myCarousel">
            <a href="https://constellationdealer.com/oem-sales-analyst-case-study/">
              OEM Sales Analyst Case Study
            </a>
          </div>
        </div>

        <div>
          <div className="myCarousel">
          <a href="https://constellationdealer.com/oem-vp-sales-case-study/">
              OEM VP Case Study
            </a>
          </div>
        </div>

        <div>
          <div className="myCarousel">
          <a href="https://constellationdealer.com/oem-marketing-manager-case-study/">
              OEM Marketing Manager Case Study
            </a>
          </div>
        </div>
      </Carousel>
      </header>
  </div>
   
  );
}

export default Testimonials;