import { Link } from "react-router-dom";


function CRM() {
    return (
      <main className="new-lp lp-targetcrm">
      <section className="lp-crm-header bg-gradient"> 
          <div className="container-wide">
              <div className="row">
                  <div className="columns six">
                      <div className="av-container av-visible"><div className="icon_hero aniview av-delay-2 animated fadeInDown" data-av-animation="fadeInDown" style={{backgroundImage: "url(&quot;../assests2/logo-TargetCRM-white.svg&quot;)", opacity: "1"}}></div></div>
                      <div className="av-container av-visible"><div className="aniview av-delay-1 animated fadeInLeft" data-av-animation="fadeInLeft" style={{opacity: "1"}}>
                                                      <h1 className="white"><strong>Communicate with customers</strong> when they want it, the&nbsp;way&nbsp;they&nbsp;want&nbsp;it.</h1>
                          <p className="hero__subtitle white">Bypass the phone trees, busy signals, & voicemails. Instantly connect customers and dealers with business texting.</p></div></div>
                  </div>
                                  <div className="columns six">
                      <div className="av-container av-visible"><div className="aniview av-delay-2 animated fadeInRight" data-av-animation="fadeInRight" style={{opacity: "1"}}>
                                                      <img src="./assests2/Target-CRM-hero.png" alt="" width="960" height="875" />
                                              </div></div>
                  </div>
              </div>
          </div>
      </section>
 
  
      <div className="av-container">
        <section className="lp-crm-video sec_bord aniview" data-av-animation="fadeIn" style={{opacity: "1"}}> 
          <div className="row">
              <div className="flex">
              <div className="columns six col_video">
                      <script src="./assests2/nihmkxi9sy.jsonp" ></script><script src="./assests/E-v1.js.download" ></script>
                      <span className="wistia_embed wistia_async_nihmkxi9sy popover=true popoverContent=link wistia_embed_initialized" style={{display:"inline",position:"relative"}} id="wistia-nihmkxi9sy-1"><div id="wistia_41.thumb_container" className="wistia_click_to_play" style={{position: "relative", display: "inline"}}>
                          <a>
                                                              <img src="./assests2/Thumbnail-Video.jpg" alt="" width="700" height="379" />
                                  <span></span>
                                                      </a>
                      </div></span>
                  </div>
                  <div className="columns six col_cta">
                      <h3>Watch this 2-minute video to see why OPE Dealers choose TargetCRM</h3>
                  </div>
              </div>
          </div>
        </section>
      </div>  
  
      <section className="lp-crm-content"> 
                        <div className="row subsec ">
                                              <div className="av-container"><div className="columns five aniview" data-av-animation="fadeInUp" style={{opacity: "1"}}>
                              <div className="subsec_i" style={{backgroundImage: "url(../assests2/TargetCRM-LP-SVG-06.svg)"}}></div>
                              <h2>Amplify your brand with targeted promotions that work</h2>
                              <ul><li>Send targeted promotions via text and email in just a few clicks</li><li>Drive brand consistency with approved graphics and templates</li><li>Customers can respond by text to turn a marketing blast into a conversation</li></ul></div></div>
                                <div className="av-container">
                                    <div className="columns seven aniview av-delay-2" data-av-animation="fadeIn" style={{opacity: "1"}}>
                                                              <img src="./assests2/banner_TargetCRM-1.png" alt="" width="1326" height="1002" />
                                    </div>
                                </div>
                        </div>
                        <div className="row subsec ">
                                                <div className="av-container">
                                                    <div className="columns seven aniview av-delay-2" data-av-animation="fadeIn" style={{opacity: "1"}}>
                                                                <img src="./assests2/banner_TargetCRM-2.png" alt="" width="1328" height="872" />
                                                    </div>
                                                </div>
                          <div className="av-container"><div className="columns five aniview" data-av-animation="fadeInUp" style={{opacity: "1"}}>
                          <div className="subsec_i" style={{backgroundImage: "url(../assests2/TargetCRM-LP-SVG-07.svg)"}}></div>
                              <h2>Put an end to phone tag</h2>
                              <ul><li>Reduce your voicemails by up to 80 percent by using text message to communicate with customers</li><li>Add a “Text Us Now!” button to your website to collect customer names, phone numbers, and messages</li></ul>                        </div></div>
                                     </div>
                          <div className="row subsec ">
                                              <div className="av-container"><div className="columns five aniview" data-av-animation="fadeInUp" style={{opacity: "1"}}>
                              <div className="subsec_i" style={{backgroundImage: "url(../images/TargetCRM-LP-SVG-08.svg)"}}></div>
                              <h2>Offer text-to-pay convenience</h2>
                              <ul><li>Give customers the flexibility to pay on a mobile device using credit card and ACH</li><li>Make pre-selling and receiving deposits easier, get prepayment before a repair, or accept payment before a delivery is made </li></ul>                        </div></div>
                          <div className="av-container"><div className="columns seven aniview av-delay-2" data-av-animation="fadeIn" style={{opacity: "1"}}>
                                                              <img src="./assests2/banner_TargetCRM-3.png" alt="" width="1454" height="978" />
                                                      </div></div>
                                      </div>
                          <div className="row subsec ">
                                              <div className="av-container"><div className="columns seven aniview av-delay-2" data-av-animation="fadeIn" style={{opacity: "1"}}>
                                                              <img src="../assests2/banner_TargetCRM-4.png" alt="" width="1460" height="872" />
                                                      </div></div>
                          <div className="av-container"><div className="columns five aniview" data-av-animation="fadeInUp" style={{opacity: "1"}}>
                          <div className="subsec_i" style={{backgroundImage: "url(../assests2/TargetCRM-LP-SVG-06.svg)"}}></div>
                              <h2>Full context on each customer</h2>
                              <ul><li>Automatically survey customers for every department and increase online reviews by up to 500%</li><li>Automatically survey customers post-sale and service, and measure NPS by department and location</li><li> View detailed invoices and full purchase history on every customer for full context alongside customer feedback and text conversations</li></ul></div></div>
                                     </div>
                          <div className="row subsec ">
                                              <div className="av-container"><div className="columns five aniview" data-av-animation="fadeInUp" style={{opacity: "1"}}>
                              <div className="subsec_i" style={{backgroundImage: "url(../assests2/TargetCRM-LP-SVG-01.svg)"}}></div>
                              <h2>Full visibility into the lead response</h2>
                              <ul><li>Dealers can stay in the loop with the TargetCRM iOS and Android mobile apps</li><li>Set and manage follow-up reminders</li><li>OEMs, owners, and managers can oversee that all leads are getting responses</li></ul>                        </div></div>
                                    <div className="av-container">
                                        <div className="columns seven aniview av-delay-2" data-av-animation="fadeIn" style={{opacity: "1"}}>
                                                              <img src="./assests2/banner_TargetCRM-5.png" alt="" width="1328" height="872" />
                                        </div>
                                    </div>
                            </div>
                            <div className="av-container">
                                <div className="row ex_btn_cta aniview av-delay-1" data-av-animation="fadeIn" style={{opacity: "1"}}>
                                </div>
                            </div>
              </section>  
  </main>
    );
  }
  
  export default CRM;